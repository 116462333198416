import React, {useState, useEffect} from 'react'
import { graphql } from "gatsby"
import {GatsbyImage,  getImage} from 'gatsby-plugin-image';
import {Styling} from '../styles/exdisplay/styled'
import Texture from '../components/animation/Texture'
import { Helmet } from 'react-helmet'
import parse from 'html-react-parser'
import ContextConsumer from "../layouts/Context"

export default function Exdisplay(props) {
  
    const [currentImage, setCurrentImage] = useState(props.data.wpExDisplay?.customHeroImage?.heroImage?.localFile)
    const [currentIndex, setCurrentIndex] = useState(0)
    const [allImages, setAllImages] = useState()
    const {manufacturer, price, originalPrice, width, height, productDescription, imageGallery} = props.data.wpExDisplay.exDisplayProductFields
    const colours = props.data.wpExDisplay.colours.nodes
    const conditions = props.data.wpExDisplay.conditions.nodes
    const title = props.data.wpExDisplay.title
    const theImage = getImage(currentImage)

    const handleAddToWishlist = props.handleAddToWishlist

    const handleSetImage = (galleryImage) => {
      setCurrentImage(galleryImage)
    }

    useEffect(()=>{
      let imagesArray = []
      
      imagesArray.push({image:props.data.wpExDisplay?.customHeroImage?.heroImage})

      if(imageGallery !== null){
        imageGallery.map(image => imagesArray.push({image:image.image}))
      }
      
      if(allImages === undefined){
        setAllImages(imagesArray)
      }

    })

    return (
        <div>
        <Helmet>
            <title>{props.data.wpExDisplay.seo.title}</title>
            <meta name="description" content={props.data.wpExDisplay.seo.metaDesc} />
            <meta name="og:description" content={props.data.wpExDisplay.seo.metaDesc} />
            <meta property="og:title" content={props.data.wpExDisplay.seo.title} />          
            <meta property="og:image" content={'https://morlandbathrooms.com'+props.data.wpExDisplay?.customHeroImage?.heroImage?.localFile.publicURL} />
        </Helmet>
        <div className="offpage-translate offpage-translate-bg"></div>
          <div className="offpage-translate">
          <Texture 
          className='texture full' 
          opacity={0.05}  
          position='absolute'
          bottom="0"
          height={''}
          top="0"
          watchScroll={false}
          />
        <Styling>
            <div className="productInfoWrapper">
           
                  <ContextConsumer>
                      {({ data, set }) => (
                        <div className="gallery">
                          <div className="large-image">
                            <div 
                            onClick={() => set({ 
                                menuOpen: !data.menuOpen, 
                                image: currentImage,
                                theGallery: allImages
                            })}
                            >
                              <GatsbyImage image={theImage} className="image" alt={'test'} />        
                            </div>
                          </div>
                          {imageGallery !== null ? 
                            <div className="thumbnails">
                              {
                                allImages ? 
                                  allImages.map((image, index) => {
                                    const galleryImage = getImage( image.image.localFile)
                                    return (
                                      <div className="thumbnail" data-caption={image.caption} onClick={() => {
                                          handleSetImage(galleryImage)
                                          set({ 
                                            imageIndex:index, 
                                          })
                                        }}
                                      >
                                        <GatsbyImage image={galleryImage} className="image" alt={'test'} />
                                      </div>
                                    )
                                  })
                                : ""
                              }
                            </div> 
                            : ""
                          }            
                        </div>
                      )}
                  </ContextConsumer>
               
              <div className="info">
                <h1 className='title baskerville medium'>{title}</h1>

                <div className="priceWrapper">
                  <div className="originalPrice">Was: {originalPrice}</div>
                  <div className="price">Now: {price}</div>
                </div>

                <div className="further-info">
                  {manufacturer?.title && manufacturer !== null && manufacturer !== undefined ?
                    <div className="manufacturer">Manufacturer: {manufacturer.title}</div>
                  : "" }
                    <div className="dimensions">
                      Dimensions (mm): {width ? `W: ${width}` : ''} {height ? `| H: ${height}` : ''}
                    </div>
                    <div className="colours">Colour: {
                      colours.map((color, index) =>
                      index === colours.length - 1 ? color.name
                      : color.name+', '
                      )
                    }</div>
                    <div className="colours">Condition: {
                      conditions.map((condition, index) =>
                      index === 0 || index === colours.length - 1 ? condition.name
                      : condition.name+', '
                      )
                    }</div>
                  
                </div>
                  <div className="text-free-text">
                    {parse(productDescription)}
                    <p>{props.data.global.siteSettings.acfOptions.productDisclaimerText}</p>
                  </div>
                  <button className="button" onClick={(e) => handleAddToWishlist(e)} data-title={title} data-price={price}>Add to wishlist</button>
                  {/* <button className="button" data-title={title} data-price={price}>Add to wishlist</button> */}
              </div>
            </div>
        </Styling>
          </div>
          </div>
    )
}

export const query = graphql`
  query($id: String!) {
    wpExDisplay(id: {eq: $id}) {
      title
      conditions {
        nodes {
          slug
          name
        }
      }
      customHeroImage {
        heroImage{
            localFile{
              publicURL
              childImageSharp {
                  gatsbyImageData(
                  width: 1500
                  placeholder: NONE
                  formats: [AUTO, WEBP]
                  )
              }
            }
        }
      }
      exDisplayProductFields {
        height
        manufacturer {
          ... on WpBrand {
            id
            title
          }
        }
        imageGallery {
          caption
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                width: 1500
                placeholder: NONE
                formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
        originalPrice
        price
        productCategorySetter
        productDescription
        width
        depth
        height
      }
      colours {
        nodes {
          slug
          name
        }
      }
      seo{
        title
        metaDesc
      }
    }
    caseHero: wpPage(title:{eq:"Ex-display"}){
      customHeroImage {
        heroImage{
            localFile{
                childImageSharp {
                    gatsbyImageData(
                    width: 1500
                    placeholder: NONE
                    formats: [AUTO, WEBP]
                    )
                }
            }
        }
      }
    }
    global: wp {
      siteSettings {
        acfOptions {
          productDisclaimerText
        }
      }
    }
  }
`