import React from 'react'
import styled from 'styled-components'

const Styling = styled.div`

    .productInfoWrapper{
    margin:0 auto;
    display:flex;
    margin-top:5rem;
    padding:8rem 4rem;
    max-width:100%;
    flex-wrap:wrap;

        @media only screen and (min-width: 760px) {
        flex-wrap:nowrap;
        }

        @media only screen and (min-width: 1200px) {
        padding:8rem 0;
        max-width:80%;
        }

        .gallery{
        width:100%;
        margin-bottom:4rem;
        z-index:10;

            @media only screen and (min-width: 760px) {
            width:50%;
            margin-bottom:0;
            }

            @media only screen and (min-width: 1200px) {
            width:40%;
            }

            .large-image{
            /* aspect-ratio:16 / 12; */
            margin-bottom:1rem;
            position:relative;
            cursor:pointer;

                /* .gatsby-image-wrapper{
                position:absolute;
                top:0;
                left:0;
                width:100%;
                height:100%;
                } */
            }

            .thumbnails{
            width: 100%;
            display:grid;
            grid-gap:1rem;
            grid-template-columns:repeat(3, 1fr);

                .thumbnail{
                aspect-ratio:16 / 12;
                overflow:hidden;
                position: relative;
                cursor:pointer;

                    .gatsby-image-wrapper{
                    position:absolute;
                    top:0;
                    left:0;
                    width:100%;
                    height:100%;
                    }

                }

            }

        }

        .info{
        width:100%;

            @media only screen and (min-width: 760px) {
            width:calc(50% - 4rem);
            margin-left:4rem;
            }

            @media only screen and (min-width: 1200px) {
            width:calc(60% - 4rem);
            }

            .title{
            margin-bottom:2rem;
            }

            .priceWrapper{
            display:flex;
            align-items:center;
            margin-bottom: 2rem;

                .price{
                font-size: clamp(1.6rem, 1.75vw, 2rem);
                line-height:1.75em;
                }
    
                .originalPrice{
                color:red;
                text-decoration:line-through;
                font-size: clamp(1.6rem / 1.25, 1.75vw / 1.25, 2rem / 1.25);
                line-height:1.75em;
                margin-right: 1rem;
                }
            }

            .further-info{
            display:flex;
            flex-wrap:wrap;

                .colours,
                .manufacturer,
                .dimensions{
                color:var(--grey);
                width:50%;
                }
                .dimensions,
                .manufacturer{
                    margin-bottom: 1rem;
                }
                
                .colours{
                margin-bottom: 2rem;
                }
            }

            .text-free-text{
            width:100%;
            position:relative;
            margin-bottom:4rem;

                p{
                margin-bottom:2rem;
                line-height:1.5em;

                    &:last-child{
                    margin-bottom:0;
                    }

                }

            }
        }

    }

`

export { Styling }